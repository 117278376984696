import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import Image from "gatsby-image"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"

/* Styles */
import "../../styles/pages/the-hybrid.scss"

const MktPage = () => {
  const { bgMkt, bgMobileMkt, logoMkt } = useStaticQuery(
    graphql`
      query {
        bgMkt: file(relativePath: { eq: "the-hybrid/mkt/mkt-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgMobileMkt: file(
          relativePath: { eq: "the-hybrid/mkt/mobile-bg-mkt.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoMkt: file(relativePath: { eq: "the-hybrid/mkt/mkt-logo.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageBgMkt = bgMkt.childImageSharp.fluid
  const imgBgMobileMkt = bgMobileMkt.childImageSharp.fluid
  const imgLogoMkt = logoMkt.childImageSharp.fluid

  // Background-Image Breakpoints
  const sources = [
    imgBgMobileMkt,
    {
      ...imageBgMkt,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout bgImage={sources}>
      <SEO
        title="mkt"
        keywords={[
          `Agencia`,
          `Transformacion Digital`,
          `The Hybrid`,
          `Growth`,
          `Rosario`,
          `Innovación`,
        ]}
      />
      <section id="mkt-page" className="the-hybrid-inner-pages mt-lg-5">
        <Container>
          <Row>
            <Col className="d-none d-lg-block pt-5" xs={12} lg={2}>
              <p className="text-white mt-4">
                <Link
                  className="text-primary text-decoration-none"
                  to="/the-hybrid"
                >
                  _thehybrid
                </Link>
                {`>mkt`}
              </p>
            </Col>
            <Col xs={12} lg={4}>
              <div className="inner-logo-images">
                <Image className="img-fluid" fluid={imgLogoMkt} />
              </div>
            </Col>
            <Col className="" xs={12} lg={6}>
              <p className="text-content text-white">
                Nuestro equipo no hace marketing tradicional, de hecho nos
                aburre, lo que hacemos es construir el próximo nivel del
                marketing. No solo creamos marcas, sino que tomamos decisiones
                inteligentes basadas en tecnología, innovación y creatividad
                para construir experiencias.
              </p>
              <p className="text-content text-white">
                Creamos experiencias memorables generando impacto, recordación y
                estrategia. No jugamos a ser marketineros. Somos nativos
                creativos fusionados con herramientas digitales y la
                inteligencia humana.
              </p>
              <p className="text-content text-white">
                Tenemos experiencia en perfiles Políticos, Corporativos,
                Gobiernos, Big Brands, Startups, Marcas Personales y todo aquel
                que requiera de creatividad y estrategia integral inteligente.
                “El diseño no es solo cómo se ve o cómo se siente. El diseño es
                cómo funciona.”
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default MktPage
